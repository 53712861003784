import * as React from 'react';

import config from '../../flags.config';

// export type Flags = {
//   [key: string]: boolean | number | string | null;
// };

export type Flags = typeof config.flags;

export type InitialFlagState<F extends Flags> = F;

export type FlagContextValue<F extends Flags = Flags> = {
  flags: F;
};

export interface UseFeatureFlagsOption<F extends Flags = Flags> {}

export const FlagContext = React.createContext<FlagContextValue>({
  flags: {
    ...config.flags,
  },
});

export interface FlagProviderProps<F extends Flags>
  extends UseFeatureFlagsOption<F> {
  children: React.ReactNode;
}

export const FlagProvider = <F extends Flags>({
  children,
}: FlagProviderProps<F>) => {
  const { flags } = config;
  return React.createElement(
    FlagContext.Provider,
    { value: { flags } },
    children
  );
};

export function useFeatureFlags<F extends Flags = Flags>(
  options: UseFeatureFlagsOption<F> = {}
) {
  const ctx = React.useContext(FlagContext);

  return ctx;
}
