import React from 'react';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import {
  Hydrate,
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { FlagProvider } from 'lib/useFlags';
import { NotificationProvider } from 'lib/useNotify';
import dynamic from 'next/dynamic';
import { i18NextConfig } from '../i18n';
import 'reflect-metadata';
import 'assets/styles/globals.scss';

const Notifications = dynamic<any>(
  () => import('lib/useNotify').then((m) => m.Notifications),
  {
    ssr: false,
  }
);

const queryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 3,
      staleTime: 1000 * 60 * 5,
      optimisticResults: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
};

const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = React.useState(
    () => new QueryClient(queryClientOptions)
  );

  const debug = process.env.NODE_ENV === 'development';

  return (
    <FlagProvider>
      <NotificationProvider>
        <Notifications />
        <QueryClientProvider client={queryClient}>
          {/* eslint-disable-next-line */}
          <Hydrate state={pageProps.dehydratedState}>
            <Component {...pageProps} />
          </Hydrate>
          {debug && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </NotificationProvider>
    </FlagProvider>
  );
};

export default appWithTranslation(App, i18NextConfig);
