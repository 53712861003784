import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import React from 'react';
import { createPortal } from 'react-dom';
import { NotificationProps } from './types';
import { useNotify } from './useNotify';

export const Notification: React.FC<NotificationProps> = (args) => {
  const { dismiss, message, title, timeout } = {
    ...args,
  };
  React.useEffect(() => {
    setTimeout(() => {
      dismiss();
    }, timeout);
  }, [dismiss, timeout]);

  const show = true;
  return (
    <Transition
      show={show}
      as={React.Fragment}
      enter='transform ease-out duration-300 transition'
      enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
      enterTo='translate-y-0 opacity-100 sm:translate-x-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
        <div className='p-4'>
          <div className='flex items-start'>
            <div className='ml-3 w-0 flex-1 pt-0.5'>
              <p className='font-medium text-sm text-gray-900'>{title}</p>
              <p className='mt-1 text-sm text-gray-500'>{message}</p>
            </div>
            <div className='ml-4 flex flex-shrink-0'>
              <button
                type='button'
                className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                onClick={dismiss}
              >
                <span className='sr-only'>Close</span>
                <FontAwesomeIcon
                  icon={faClose}
                  className='h-6 w-6 fill-current'
                  fixedWidth
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const Notifications: React.FC = () => {
  const { notify, notifications, destroy } = useNotify();

  return createPortal(
    <div
      aria-live='assertive'
      className='pointer-events-none fixed inset-0 z-[999] flex items-end px-4 py-6 sm:items-start sm:p-6'
    >
      <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            {...notification}
            dismiss={() => destroy(notification.id)}
          />
        ))}
      </div>
    </div>,
    document.body
  );
};
