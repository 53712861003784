import { secondsToMilliseconds } from 'date-fns';
import { nanoid } from 'nanoid';
import React from 'react';
import {
  AddNotification,
  NotificationContextValue,
  NotificationData,
} from './types';

export const NotificationContext =
  React.createContext<NotificationContextValue>({
    notifications: [],
    notify: () => {},
    destroy: () => {},
  });

export const NotificationProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [notifications, setNotifications] = React.useState<NotificationData[]>(
    []
  );

  const addNotification = React.useCallback(
    (notification: AddNotification) => {
      setNotifications([
        ...notifications,
        {
          ...{ id: nanoid(3), timeout: secondsToMilliseconds(30) },
          ...notification,
        },
      ]);
    },
    [notifications]
  );

  const removeNotification = React.useCallback(
    (notificationId: string) => {
      setNotifications(notifications.filter((n) => n.id !== notificationId));
    },
    [notifications]
  );

  const value = React.useMemo(
    () => ({
      notifications,
      notify: addNotification,
      destroy: removeNotification,
    }),
    [addNotification, notifications, removeNotification]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = React.useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationProvider'
    );
  }
  return context;
};
