import { useNotificationContext } from './context';

export const useNotify = () => {
  const context = useNotificationContext();
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationProvider'
    );
  }
  return context;
};
