const path = require('path');

/** @type {import('next').NextConfig['i18n']} */
const i18n = {
  defaultLocale: 'en',
  locales: ['en'],
};

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n,
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  ...(typeof window === 'undefined'
    ? { localePath: path.resolve('./public/locales') }
    : {}),
};
